interface ITokenType {
    PAR_OPEN: number;
    PAR_CLOSE: number;
    OP_NOT: number;
    BINARY_AND: number;
    BINARY_OR: number;
    LITERAL: 'LITERAL';
    END: 'END';
    LEAF: 'LEAF';
    ATOMIC: 'ATOMIC';
}

const TOKEN_TYPE: ITokenType = {
    PAR_OPEN: '('.charCodeAt(0),
    PAR_CLOSE: ')'.charCodeAt(0),
    OP_NOT: '!'.charCodeAt(0),
    BINARY_AND: '&'.charCodeAt(0),
    BINARY_OR: '|'.charCodeAt(0),
    LITERAL: 'LITERAL',
    END: 'END',
    LEAF: 'LEAF',
    ATOMIC: 'ATOMIC'
};

type TTokenType = ITokenType[keyof ITokenType];

interface IToken {
    type: TTokenType;
    value: string;
}

type TLiteralChecker = (literal: string) => boolean;

class PrefixNotation {
    prefixedTokens: IToken[];

    constructor(tokens: IToken[]) {
        const queue: IToken[] = [];
        const stack: IToken[] = [];
        tokens.forEach((token) => {
            switch (token.type) {
                case TOKEN_TYPE.LITERAL:
                    queue.unshift(token);
                    break;
                case TOKEN_TYPE.BINARY_AND:
                case TOKEN_TYPE.BINARY_OR:
                case TOKEN_TYPE.OP_NOT:
                case TOKEN_TYPE.PAR_OPEN:
                    stack.push(token);
                    break;
                case TOKEN_TYPE.PAR_CLOSE:
                    while (stack.length && stack[stack.length - 1].type !== TOKEN_TYPE.PAR_OPEN) {
                        queue.unshift(stack.pop()!);
                    }
                    stack.pop();
                    if (stack.length && stack[stack.length - 1].type === TOKEN_TYPE.OP_NOT) {
                        queue.unshift(stack.pop()!);
                    }
                    break;
                default:
                    break;
            }
        });

        this.prefixedTokens = (stack.length && [...stack.reverse(), ...queue]) || queue;
    }

    * generator(): IterableIterator<IToken> {
        for (let index = 0; index < this.prefixedTokens.length - 1; index++) {
            yield this.prefixedTokens[index];
        }
        return this.prefixedTokens[this.prefixedTokens.length - 1];
    }
}

class Node {
    private static and(left: Node | null, right: Node | null) {
        return new Node(TOKEN_TYPE.BINARY_AND, left, right);
    }

    private static not(node: Node | null) {
        return new Node(TOKEN_TYPE.OP_NOT, node);
    }

    private static or(left: Node | null, right: Node | null) {
        return new Node(TOKEN_TYPE.BINARY_OR, left, right);
    }

    private static literal(literal: string) {
        return new Node(TOKEN_TYPE.LEAF, null, null, literal);
    }

    private static makeTree = (gen: IterableIterator<IToken>): Node | null => {
        const data = gen.next().value;

        switch (data.type) {
            case TOKEN_TYPE.LITERAL:
                return Node.literal(data.value);
            case TOKEN_TYPE.OP_NOT:
                return Node.not(Node.makeTree(gen));
            case TOKEN_TYPE.BINARY_AND: {
                return Node.and(Node.makeTree(gen), Node.makeTree(gen));
            }
            case TOKEN_TYPE.BINARY_OR: {
                return Node.or(Node.makeTree(gen), Node.makeTree(gen));
            }
            default:
                return null;
        }
    };

    private static evaluator(tree: Node, literalChecker: TLiteralChecker): boolean {
        if (tree.isLeaf()) {
            return literalChecker(tree.getLiteralValue());
        }

        if (tree.op === TOKEN_TYPE.OP_NOT) {
            return !Node.evaluator(tree.left!, literalChecker);
        }

        if (tree.op === TOKEN_TYPE.BINARY_OR) {
            return (Node.evaluator(tree.left!, literalChecker) || Node.evaluator(tree.right!, literalChecker));
        }

        if (tree.op === TOKEN_TYPE.BINARY_AND) {
            return (Node.evaluator(tree.left!, literalChecker) && Node.evaluator(tree.right!, literalChecker));
        }
        return false;
    }

    static eval(prefixNotation: PrefixNotation, literalChecker: TLiteralChecker): boolean {
        const tree: Node = Node.makeTree(prefixNotation.generator())!;
        return Node.evaluator(tree, literalChecker);
    }

    private constructor(private op: TTokenType,
                        private left: Node | null,
                        private right: Node | null = null,
                        private literal: string | null = null) {
    }

    isLeaf() {
        return this.op === TOKEN_TYPE.LEAF;
    }

    isAtomic() {
        return (this.isLeaf() || (this.op === TOKEN_TYPE.OP_NOT && this.left!.isLeaf()));
    }

    getLiteralValue(): string {
        return this.literal!;
    }

}
function tokenize(exp: string): IToken[] {

  let literal = '';
  const tokens: IToken[] = [];
  for (const char of exp) {
    const code = char.charCodeAt(0);
    switch (code) {
      case TOKEN_TYPE.PAR_OPEN:
      case TOKEN_TYPE.PAR_CLOSE:
      case TOKEN_TYPE.OP_NOT:
      case TOKEN_TYPE.BINARY_AND:
      case TOKEN_TYPE.BINARY_OR:
        if (literal) {
          tokens.push({
            type: TOKEN_TYPE.LITERAL,
            value: literal
          });
          literal = '';
        }

        tokens.push({
          type: code,
          value: char
        });
        break;
      default:
        literal += char;
    }
  }

  if (literal) {
    tokens.push({
      type: TOKEN_TYPE.LITERAL,
      value: literal
    });
  }

  return tokens;
}

/**
 * const expr = '(ROLE_1 & (ROLE_2 | ROLE_3))';
 * console.log(evalBoolExpr(expr, ['ROLE_1', 'ROLE_3'])); // true
 * console.log(evalBoolExpr(expr, (t) => ['ROLE_2', 'ROLE_4'].indexOf(t) !== -1)); // false
 */
export function evalBoolExpr(exp: string, checkFuncOrTokens: TLiteralChecker | string[]): boolean {
  const checker: TLiteralChecker =
    (checkFuncOrTokens instanceof Array)
      ? (t) => checkFuncOrTokens.indexOf(t) > -1
      : checkFuncOrTokens;
  const tokens = tokenize(exp.replace(new RegExp(' ', 'g'), ''));
  const prefixNotation = new PrefixNotation(tokens);
  return Node.eval(prefixNotation, checker);

}

