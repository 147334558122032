import {
  AfterContentInit,
  Component,
  DestroyRef,
  inject,
  Injectable,
  Input,
  OnInit,
  Pipe,
  PipeTransform
} from '@angular/core';
import {OnDemandResourceLoaderService} from '../shared/services/resources/on-demand-resource-loader.service';
import {DepartmentsResourceService} from './resources/dictionaries/departments-resource.service';
import {AsyncPipe, DatePipe, JsonPipe, NgIf} from '@angular/common';
import {CollapsiblePanelComponent} from '../shared/components/collapsible-panel.component';
import {ButtonModule} from 'primeng/button';
import {FormsModule} from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {CheckboxModule} from 'primeng/checkbox';
import {
  ActivatedRoute,
  NavigationEnd,
  Route,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import {CommonPageComponent} from '../shared/components/page/common-page.component';
import {MenuItem, SharedModule} from 'primeng/api';
import {SkillsResourceService} from './resources/dictionaries/skills-resource.service';
import {getCurrentPeriodValue, PeriodChooserComponent} from '../shared/components/period-chooser.component';
import {ICommonPageEvent, notifyCommonPageTitle} from '../shared/components/page/common-page.service';
import {LifeCycleHookDirective} from '../shared/components/life-cycle-hook.directive';
import {CommonPageHeaderDelegateComponent} from '../shared/components/page/common-page-header-delegate.component';
import {IPeriod} from '../api/shared/common';
import {getStorageItem, isAllNullOrUndefined, routeParamsToObject, setStorageItem} from '../shared/util/util';
import {IRouterLinkItem, NavDropdownComponent} from '../shared/components/nav-dropdown.component';
import {I18nService} from '../shared/services/i18n/i18n.service';
import {UserProfileService} from '../shared/auth-provider/user-profile.service';
import { InputTextModule } from 'primeng/inputtext';
import { PermissionsAwareDirective } from '../shared/auth-provider/permissions';


const ROUTER_LINK_ITEMS: Array<IRouterLinkItem> = [
  {
    label: 'One', routerLink: ['/test/one']
  },
  {
    label: 'Two', routerLink: ['/test/two']
  }
];




@Component({
  selector: 'test-collapsible',
  template: `
    <app-common-page-header-delegate>
      <ng-template pTemplate="bottomHeader">
        <app-nav-dropdown [items]="routerLinkItems"></app-nav-dropdown>
      </ng-template>
    </app-common-page-header-delegate>
    <div style="height: 400px; width: fit-content; background-color: #FFF" class="flex border-1 p-3">
      <div style="width: 300px">
        <p-dropdown [(ngModel)]="dep" lifecycle #l="lifecycle"
                    [options]="!l.initialized ? null : $any(onDemandLoader.observe('departments') | async)"
                    optionValue="id" optionLabel="name" [autoDisplayFirst]="false"
                    placeholder="Department">
        </p-dropdown>
      </div>
      <p-button label="Add & Update" (onClick)="addAndUpdate()"></p-button>
      <div style="background-color: yellow;">
        <app-collapsible-panel [isExpanded]="false" [width]="'500px'" expandTooltip="Expand Panel">
          <div class="flex" style="height: 100%; width: 500px">
            <div class="p-2 flex-1">
              Expand to right
            </div>
            <div style="background-color: cyan; ; padding-right: 0">
              <app-collapsible-panel [isExpanded]="true" [width]="'200px'">
                <div class="p-2" style="background-color: #fff">
                  Expand to right 2
                </div>
              </app-collapsible-panel>
            </div>
          </div>
        </app-collapsible-panel>
      </div>
    </div>
  `,
  imports: [
    CollapsiblePanelComponent,
    ButtonModule,
    DropdownModule,
    FormsModule,
    AsyncPipe,
    LifeCycleHookDirective,
    CommonPageHeaderDelegateComponent,
    NavDropdownComponent
  ],
  standalone: true
})
export class TestCollapsible implements OnInit {
  dep?: string;
  routerLinkItems = ROUTER_LINK_ITEMS;

  constructor(public onDemandLoader: OnDemandResourceLoaderService,
              public departments: DepartmentsResourceService, skills: SkillsResourceService) {
    notifyCommonPageTitle('Skill', 'skillsCount');
  }

  ngOnInit(): void {
  }

  genId(): string {
    return Math.random().toString(36).slice(2);
  }

  addAndUpdate(): void {
    this.departments.createEntity({name: this.genId(), isArchived: false}).subscribe((e) => {
      console.log('Created Department', JSON.stringify(e));
      this.dep = e.id;
      this.departments.updateEntity(e.id, {name: '@@@' + e.name, isArchived: true}).subscribe();
    });
  }
}

@Pipe({
  name: 'typeOfEvent',
  standalone: true
})
export class TypeOfEventPipe implements PipeTransform {
  transform(value: any): string {
    return value?.constructor?.name;
  }
}


@Component({
  selector: 'app-test-two',
  template: `
    <app-common-page-header-delegate entityName="Department" onDemandCountMethod="departmentsCount"
                                     (onPageEvents)="event = $event">
      <ng-template pTemplate="bottomHeader">
        <div class="flex align-items-center">
          <app-nav-dropdown [items]="routerLinkItems" class="mr-2"></app-nav-dropdown>
        </div>
        <app-period-chooser [(ngModel)]="period" (onPeriodChange)="saveState()"></app-period-chooser>
      </ng-template>
    </app-common-page-header-delegate>
    <ng-container>
      <div>
        {{ event | typeOfEvent }}: {{ event | json }};
        period: {{ period | json }}  {{ period?.start | date: 'fullDate' }}
      </div>

      <div>
        <h2>Permissions Demo</h2>
        <input pInputText [ngModel]="'strategy: disable'" strategy="disable" permissionsAware="TENANT_ALL1" />
        <input pInputText [ngModel]="'strategy: hide'" strategy="hide" permissionsAware="TENANT_ALL1" />
        <input pInputText [ngModel]="'strategy: none'" strategy="none" permissionsAware="TENANT_ALL1" />
        <input pInputText [ngModel]="'ok'"/>
      </div>
    </ng-container>
  `,
  standalone: true,
  imports: [
    PeriodChooserComponent, CommonPageHeaderDelegateComponent, SharedModule, JsonPipe, TypeOfEventPipe, FormsModule,
    NavDropdownComponent, DatePipe, DropdownModule, InputTextModule, PermissionsAwareDirective
  ]
})
export class TestTwoComponent implements OnInit {
  period: IPeriod | null = null;
  event?: ICommonPageEvent;
  useStorage = false;
  routerLinkItems = ROUTER_LINK_ITEMS;
  constructor(departments: DepartmentsResourceService, protected router: Router,
              protected activatedRoute: ActivatedRoute) {
    if (!this.useStorage) {
      routeParamsToObject<{ period: IPeriod }>({
        period: {
          isQuery: true,
          type: 'base64',
          defaultValue: getCurrentPeriodValue('year')
        }
      }).subscribe((r) => {
        this.period = r.period;
      });
    }
  }

  ngOnInit(): void {
    if (this.useStorage) {
      const stored = getStorageItem<typeof this.period>('test.two');
      if (stored !== undefined) {
        this.period = stored as any;
      }
    }
  }

  saveState(): void {
    console.log(btoa(JSON.stringify(this.period)));
    if (!this.useStorage) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {period: btoa(JSON.stringify(this.period))},
        queryParamsHandling: 'merge',
        replaceUrl: true
      });
    } else {
      setStorageItem('test.two', this.period);
    }
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////

@Component({
  selector: 'app-test',
  template: `
    <app-common-page [navMenu]="navMenu" stateKey="test">
    </app-common-page>
  `,
  standalone: true,
  imports: [
    DropdownModule, FormsModule, ButtonModule, CollapsiblePanelComponent, AsyncPipe, ConfirmDialogModule,
    CheckboxModule, NgIf, CommonPageComponent, RouterOutlet, RouterLink, PeriodChooserComponent
  ]
})
export class TestComponent {
  navMenu: Array<MenuItem> = [
    {
      label: 'One', routerLink: ['one']
    },
    {
      label: 'Two', routerLink: ['two']
    }
  ];
  constructor(userProfileService: UserProfileService) {
  }
}

//////////////////////////////////////////
export default [
  {path: '', redirectTo: 'one', pathMatch: 'full'},
  {path: 'one', component: TestCollapsible},
  {path: 'two', component: TestTwoComponent}

] satisfies Array<Route>;

