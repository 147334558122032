import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DateTime} from 'luxon';
import {FormsModule} from '@angular/forms';
import {AbstractControlValueAccessor} from './abstract-control-value-accessor';
import {IPeriod, PERIOD_TYPES, TPeriodType} from '../../api/shared/common';
import {OptionsPipe} from './options.pipe';
import {DropdownModule} from 'primeng/dropdown';
import {CalendarModule} from 'primeng/calendar';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {RippleModule} from 'primeng/ripple';
import {ButtonModule} from 'primeng/button';
import {DatePipe, NgIf, NgSwitch, NgSwitchCase} from '@angular/common';
import {valueAccessorProvider} from '../util/util';
import { TranslateModule, TranslateService } from '@ngx-translate/core';


interface IInterval {
  start: DateTime;
  end: DateTime;
}


const CHOOSER_PERIOD_TYPES = [...PERIOD_TYPES, 'all time'] as const;
type TChooserPeriodType = typeof CHOOSER_PERIOD_TYPES[number];
const DATE_TIME_UNITS = ['day', 'week', 'month', 'quarter', 'year'] as const;
type TDateTimeUnit = typeof DATE_TIME_UNITS[number];

function isNavigableDateTimeUnit(periodType: TChooserPeriodType): periodType is TDateTimeUnit {
  return DATE_TIME_UNITS.includes(periodType as any);
}

export function getCurrentPeriodValue(periodType: TPeriodType): IPeriod {
  const unit = isNavigableDateTimeUnit(periodType) ? periodType : 'day';
  return {
    start: DateTime.now().startOf(unit).toJSDate(),
    end: DateTime.now().endOf(unit).toJSDate(),
    type: periodType
  };
}

@Component({
  selector: 'app-period-chooser',
  template: `
      @if (!['all time', 'range'].includes(periodType)) {
          <button pButton pRipple type="button"
                  [label]="'shared.period.current.' + periodType | translate"
                  [disabled]="isDisabled"
                  class="p-button-outlined" (click)="setThis()"></button>
          <button pButton pRipple type="button" icon="pi pi-chevron-left" [disabled]="isDisabled || !canPrev()"
                  class="p-button-rounded p-button-text ml-1" style="min-width: 2.75rem" (click)="prev()"></button>
          <button pButton pRipple type="button" icon="pi pi-chevron-right" [disabled]="isDisabled || !canNext()"
                  class="p-button-rounded p-button-text ml-1" style="min-width: 2.75rem" (click)="next()"></button>
      }
      <div class="ml-1 text-gray-600">
          @switch (periodType) {
              @case ('day') {
                  {{ interval.start.toJSDate() | date: 'mediumDate' }}
              }
              @case ('week') {
                  {{ interval.start.toJSDate() | date: 'EEE, MMM dd, yyyy' }} - {{ interval.end.toJSDate() | date: 'EEE, MMM dd, yyyy' }}
              }
              @case ('month') {
                  {{ interval.start.toJSDate() | date: 'MMM, yyyy' }}
              }
              @case ('quarter') {
                  {{ interval.start.toJSDate() | date: 'MMM, yyyy' }} - {{ interval.end.toJSDate() | date: 'MMM, yyyy' }}
              }
              @case ('year') {
                  {{ interval.start.toJSDate() | date: 'yyyy' }}
              }
              @case ('all time') {
                  All Time
              }
              @case ('range') {
                  {{ interval.start.toJSDate() | date: 'MMM dd, yyyy' }} - {{ interval.end.toJSDate() | date: 'MMM dd, yyyy' }}
              }
          }
      </div>
      <button *ngIf="['day', 'month', 'year', 'week', 'range'].includes(periodType)"
              pButton pRipple type="button" icon="pi pi-calendar"
              class="p-button-rounded p-button-text ml-1"
              [disabled]="isDisabled"
              (click)="!opCalendar.overlayVisible ? onShowCalendar() : null;  opCalendar.toggle($event)">
      </button>
      <p-overlayPanel #opCalendar [style]="{width: '420px'}" [dismissable]="true">
          <p-calendar *ngIf="opCalendar.overlayVisible" [(ngModel)]="calendar"
                      [firstDayOfWeek]="1"
                      [disabled]="isDisabled"
                      [inline]="true"
                      [showWeek]="true"
                      [minDate]="minDate!" [maxDate]="maxDate!"
                      [view]="periodType === 'day' || periodType === 'week' || periodType === 'range' ? 'date' : (periodType === 'month' ? 'month' : 'year')"
                      [selectionMode]="periodType === 'range' ? 'range' : 'single'"
                      styleClass="w-full"
                      (onSelect)="periodType !== 'range' || calendar[1] != null ? opCalendar.hide() : null; onSelectCalendar()"></p-calendar>
      </p-overlayPanel>
      <span *ngIf="showUnits" class="p-float-label ml-2">
      <p-dropdown [(ngModel)]="periodType"
                  [autoDisplayFirst]="false"
                  [disabled]="isDisabled"
                  [options]="periodOptions"
                  styleClass="p-inputtext-sm"
                  [style]="{width: '130px'}"
                  (ngModelChange)="onChangePeriodType()">
      </p-dropdown>
      <label>Unit</label>
    </span>
      <div *ngIf="false" style="position: absolute; bottom: -10px; left: 0; font-size: 10px">
          {{ value?.start | date: 'yy-MM-dd hh:mm' }} - {{ value?.end | date: 'yy-MM-dd hh:mm' }}
      </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        position: relative;
      }
    `
  ],
  providers: [valueAccessorProvider(PeriodChooserComponent)],
  standalone: true,
  imports: [
    NgIf, ButtonModule, RippleModule, NgSwitch, NgSwitchCase, OverlayPanelModule, CalendarModule, FormsModule,
    DropdownModule, DatePipe, OptionsPipe, TranslateModule
  ]
})
export class PeriodChooserComponent extends AbstractControlValueAccessor<IPeriod> implements OnChanges {
  @Input() unitFilter?: Array<TChooserPeriodType>;
  @Input() showUnits = true;
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() useBusinessWeeks = true;
  @Output() onPeriodChange: EventEmitter<IPeriod> = new EventEmitter<IPeriod>();
  periodTypes: Array<TChooserPeriodType> = [...CHOOSER_PERIOD_TYPES];
  periodOptions: Array<{label: string; value: TChooserPeriodType}>
  calendar: any;
  periodType: TChooserPeriodType = 'month';

  interval: IInterval = {
    start: DateTime.now().startOf('month'),
    end: DateTime.now().endOf('month')
  };

  constructor(translate: TranslateService) {
    super();
    this.periodOptions = this.periodTypes.map((pt) =>
      ({label: translate.instant(`shared.period.types.${pt}`) as string, value: pt}));
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.unitFilter) {
      if (this.unitFilter) {
        if (this.unitFilter.length > 0 && this.unitFilter.indexOf(this.periodType) === -1) {
          this.periodType = this.unitFilter[0];
          if (this.periodType !== 'all time') {
            this.interval.start = DateTime.now().startOf(isNavigableDateTimeUnit(this.periodType) ? this.periodType : 'day');
            this.interval.end = DateTime.now().endOf(isNavigableDateTimeUnit(this.periodType) ? this.periodType : 'day');
          }
        }
        this.periodTypes = this.unitFilter;
      }
    }
  }

  setThis(): void {
    if (isNavigableDateTimeUnit(this.periodType)) {
      this.interval.start = DateTime.now().startOf(this.periodType);
      this.interval.end = DateTime.now().endOf(this.periodType);
      this.setPeriod();
    }
  }

  canPrev(): boolean {
    if (!isNavigableDateTimeUnit(this.periodType) || !this.minDate) {
      return true;
    }
    return this.interval.start.minus({[this.periodType]: 1}).toJSDate() >= this.minDate;
  }

  canNext(): boolean {
    if (!isNavigableDateTimeUnit(this.periodType) || !this.maxDate) {
      return true;
    }
    return this.interval.start.endOf(this.periodType).toJSDate() < this.maxDate;
  }

  prev(): void {
    if (isNavigableDateTimeUnit(this.periodType)) {
      if (this.useBusinessWeeks && this.periodType === 'week') {
        let newStart: DateTime;
        if (this.interval.start.weekday !== 1) {
          newStart = this.interval.start.startOf('week');
          this.interval.end = newStart.endOf('month');
        } else {
          newStart = this.interval.start.minus({['week']: 1});
          if (this.interval.start.startOf('month').month !== newStart.month && this.interval.start.day !== 1) {
            newStart = this.interval.start.startOf('month');
          }
          this.interval.end = newStart.endOf('week');
        }
        this.interval.start = newStart;
      } else {
        this.interval.start = this.interval.start.minus({[this.periodType]: 1});
        this.interval.end = this.interval.start.endOf(this.periodType);
      }
      this.setPeriod();
    }
  }

  next(): void {
    if (isNavigableDateTimeUnit(this.periodType)) {
      if (this.useBusinessWeeks && this.periodType === 'week') {
        let newEnd: DateTime;
        let newStart: DateTime;
        if (this.interval.end.weekday !== 7) {
          newEnd = this.interval.end.endOf('week');
          this.interval.start = newEnd.startOf('month');
        } else {
          newEnd = this.interval.end.plus({['week']: 1});
          if (this.interval.end.endOf('month').month !== newEnd.month && this.interval.end.day !== this.interval.end.daysInMonth) {
            newEnd = this.interval.end.endOf('month');
          }
          this.interval.start = newEnd.startOf('week');
        }
        this.interval.end = newEnd;
      } else {
        this.interval.start = this.interval.start.plus({[this.periodType]: 1});
        this.interval.end = this.interval.start.endOf(this.periodType);
      }
      this.setPeriod();
    }
  }

  private adjustPeriodIfNeed(): void {
    if (isNavigableDateTimeUnit(this.periodType)) {
      const start = this.interval.start;
      this.interval.start = this.interval.start.startOf(this.periodType);
      this.interval.end = this.interval.start.endOf(this.periodType);

      if (this.useBusinessWeeks && this.periodType === 'week'
        && this.interval.start.month != this.interval.end.month) {
        if (start.month === this.interval.start.month) {
          this.interval.end = start.endOf('month');
        } else {
          this.interval.start = start.startOf('month');
        }
      }
    }
  }

  onChangePeriodType(): void {
    this.adjustPeriodIfNeed();
    this.setPeriod();
  }

  private setPeriod(): void {
    if (this.periodType === 'all time') {
      this.value = null;
    } else {
      this.value = {
        start: this.interval.start.toJSDate(),
        end: this.interval.end.toJSDate(),
        type: this.periodType
      }
    }
    this.onModelChange(this.value);
    this.onPeriodChange.emit(this.value!);
  }

  onSelectCalendar(): void {
    if (this.periodType !== 'range') {
      this.interval.start = DateTime.fromJSDate(this.calendar);
      this.interval.end = DateTime.fromJSDate(this.calendar);
      this.adjustPeriodIfNeed();
    } else {
      this.interval.start = DateTime.fromJSDate(this.calendar[0]).startOf('day');
      if (this.calendar.length < 2 || this.calendar[1] == null) {
        this.interval.end = this.interval.start.endOf('day');
      } else {
        this.interval.end = DateTime.fromJSDate(this.calendar[1]).endOf('day');
      }
    }
    this.setPeriod();
  }

  onShowCalendar(): void {
    if (this.periodType === 'range') {
      this.calendar = [this.interval.start.toJSDate(), this.interval.end.toJSDate()];
    } else {
      this.calendar = this.interval.start.toJSDate();
    }
  }

  override writeValue(value: IPeriod | null | undefined): void {
    if (value) {
      this.periodType = value.type;
      if (isNavigableDateTimeUnit(value.type)) {
        this.interval.start = DateTime.fromJSDate(value.start).startOf(value.type);
        this.interval.end = DateTime.fromJSDate(value.end).endOf(value.type);
      } else {
        this.interval.start = DateTime.fromJSDate(value.start);
        this.interval.end = DateTime.fromJSDate(value.end);
      }
    } else {
      this.periodType = 'all time';
    }
    this.value = value;
  }

}
