import {Observable} from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import {inject} from '@angular/core';

export interface IAuthUser {
    familyName?: string;
    givenName?: string;
    email?: string;
    avatar: null | string;
}

export abstract class AuthProviderService {
    protected _isAuthenticated$!: Observable<boolean>;
    protected _user$!: Observable<IAuthUser | null>;
    protected _isLoading$!: Observable<boolean>;

    get user$(): Observable<IAuthUser | null> {
        return this._user$;
    }

    get isAuthenticated$(): Observable<boolean> {
        return this._isAuthenticated$;
    }

    get isLoading$(): Observable<boolean> {
        return this._isLoading$;
    }

    abstract login(): Promise<void>;

    abstract logout(): Promise<void>;

    abstract signUp(): Promise<void>;

    abstract getNativeService(): any;
}

export interface IAuthHttpInterceptorConfig {
    allowedList: Array<string>;
}

export interface IAuthProviderConfig {
    clientId: string;
    domain: string;
    redirectURL: string;
    logoutRedirectURL: string;
    audience?: string;
    httpInterceptorConfig: IAuthHttpInterceptorConfig;
}


export abstract class AuthProviderGuard implements CanActivate  {
    abstract canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>;
}

export const authProviderGuardFn: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthProviderGuard).canActivate(route, state);

