import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, catchError, concatMap, filter, Observable, of} from 'rxjs';
import {AuthProviderService} from './auth-provider';
import {map, tap} from 'rxjs/operators';
import {setFavIcon} from '../util/util';
import {COMMON_LIB_CONFIG_TOKEN, ICommonLibConfig} from '../common-lib-config';
import {HttpClient} from '@angular/common/http';
import {intersection, omit} from 'lodash';
import {evalBoolExpr} from '../util/bool-expr-parser';


import {IUserMePayload} from '../../api/shared/auth-user-api';
import {ITenant} from '../../api/shared/common';
import {IPermissionsAware, TPermissionExpr} from './permissions';

export type IOrganizationInfo = ITenant;
export type IUserProfile = Omit<IUserMePayload, 'tenant' | 'tenants'> & { organization: IOrganizationInfo };



@Injectable({
  providedIn: 'root'
})
export class UserProfileService implements IPermissionsAware {
  private _userProfile: IUserProfile | null = null;
  private userInfoSubject = new BehaviorSubject<IUserProfile | null>(null);
  readonly userProfile$: Observable<IUserProfile | null> = this.userInfoSubject.asObservable();


  get userProfile(): IUserProfile | null {
    return this._userProfile;
  }

  authorize(): void {
    const authProvider = inject(AuthProviderService);
    const http = inject(HttpClient);
    const commonConfig = inject<ICommonLibConfig>(COMMON_LIB_CONFIG_TOKEN);

    authProvider.isAuthenticated$.pipe(
      filter((isAuthenticated) => isAuthenticated),
      concatMap(() => http.get<IUserMePayload>(commonConfig.authorizationUrl).pipe(
        catchError((error) => of(null)),
        map((userInfo) => (userInfo ? omit({...userInfo, organization: userInfo.tenant}, [
          'tenant', 'tenants'
        ]) : null)),
        tap((userInfo) => this.updateUserInfo(authProvider, userInfo))
      )),
    ).subscribe();
  }

  private updateUserInfo(authProvider: AuthProviderService, userInfo: IUserProfile | null): void {
    if (userInfo) {
      this._userProfile = userInfo;
      this.userInfoSubject.next(userInfo);
      if (userInfo.organization.avatar) {
        setFavIcon(userInfo.organization.avatar);
      }
    } else {
      setTimeout(() => {
        authProvider.logout();
      }, 2000); // give to a user chance to show alert
    }
  }

  get permissions(): Array<string> {
    return this.getPermissions(this._userProfile);
  }

  isDenied(arrayOrExpr: TPermissionExpr): boolean {
    return this.checkDenied(arrayOrExpr, this.permissions);
  }

  isGranted(arrayOrExpr: TPermissionExpr): boolean {
    return !this.isDenied(arrayOrExpr);
  }

  private getPermissions(userProfile: IUserProfile | null): Array<string> {
    return userProfile?.permissions || [];
  }

  private checkDenied(arrayOrExpr: TPermissionExpr, permissions: Array<string>): boolean {
    if (!arrayOrExpr) {
      return true;
    }
    if (typeof arrayOrExpr === 'string') {
      return !evalBoolExpr(<string>arrayOrExpr, permissions);
    } else {
      return !intersection(<string[]>arrayOrExpr, permissions).length;
    }
  }

}
