import {MenuItem} from 'primeng/api';
import {AfterViewChecked, AfterViewInit, Directive, ElementRef, inject, Input, Renderer2} from '@angular/core';
import {UserProfileService} from './user-profile.service';
import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree} from '@angular/router';
import {take} from 'rxjs/operators';
import {filter, Observable, of, switchMap} from 'rxjs';
import {findParentElement} from '../util/util';

export type TPermissionExpr = Array<string> | string;

export interface IPermissible {
  permissionExpr?: TPermissionExpr;
}


function isPermissible(obj: any): obj is IPermissible & { permissionExpr: TPermissionExpr } {
  if (obj && typeof obj === 'object') {
    return typeof obj.permissionExpr === 'string'
      || Array.isArray(obj.permissionExpr) && obj.permissionExpr.every((item: any) => typeof item === 'string');
  }
  return false;
}

export interface IPermissionsAware {
  readonly permissions: Array<string>;

  isDenied(roles: TPermissionExpr): boolean;

  isGranted(roles: TPermissionExpr): boolean;
}

export function removeDeniedMenuItems(menu: Array<MenuItem>): Array<MenuItem> {
  const userProfileService = inject(UserProfileService);
  return menu.filter((n) => isPermissible(n) ? userProfileService.isGranted(n.permissionExpr) : true);
}

export const permissibleGuardFn: (arrayOrExpr: TPermissionExpr) => CanActivateFn = (arrayOrExpr: TPermissionExpr) =>
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
    const userProfileService = inject(UserProfileService);
    return userProfileService.userProfile$.pipe(
      filter(userProfile => userProfile !== null),
      take(1),
      switchMap(() => userProfileService.isGranted(arrayOrExpr) ? of(true) : of(new UrlTree()))
    );
  };


@Directive({
  selector: '[permissionsAware]',
  standalone: true
})
export class PermissionsAwareDirective implements AfterViewInit, AfterViewChecked {
  private _permissions: TPermissionExpr = [];

  @Input() set permissionsAware(permissions: TPermissionExpr) {
    this._permissions = permissions;
  }

  @Input() strategy: 'disable' | 'hide' | 'none' | 'shield' = 'disable';

  @Input() viewAsBlock = false;

  @Input() viewStyle?: { [style: string]: string | number };

  @Input() hideText = '';

  @Input() childrenSelector?: string;

  @Input() parentSelector?: string;

  private parentEl: any;

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              protected userProfileService: UserProfileService) {
  }

  ngAfterViewInit() {
    if (this.parentSelector) {
      this.parentEl = findParentElement(this.el.nativeElement, this.parentSelector, false);
    }
    if (!this.childrenSelector && this.userProfileService.isDenied(this._permissions)) {
      this.perform();
    }
  }

  ngAfterViewChecked() {
    if (this.childrenSelector && this.userProfileService.isDenied(this._permissions)) {
      this.perform();
    }
  }

  private applyStyle(style?: { [style: string]: string | number }): void {
    if (!style) return;

    const elements = this.childrenSelector
      ? this.el.nativeElement.querySelectorAll(this.childrenSelector)
      : [this.parentEl || this.el.nativeElement];

    elements.forEach((element: any) => {
      Object.entries(style).forEach(([key, value]) => this.renderer.setStyle(element, key, value));
    });
  }


  private perform() {
    switch (this.strategy) {
      case 'disable':
        this.disable();
        break;
      case 'hide':
        this.hide();
        break;
      case 'shield':
        this.shield();
        break;
      case 'none':
        this.none();
        break;
    }
  }

  private disable() {
    this.applyStyle({
      display: this.viewAsBlock ? 'block' : '',
      opacity: 0.7,
      filter: 'grayscale(7%)',
      'pointer-events': 'none',
      ...this.viewStyle
    });
  }

  private shield() {
    this.renderer.setProperty(this.el.nativeElement, 'textContent', '');
    const shieldDiv = this.renderer.createElement('div');
    const textNode = this.renderer.createText(this.hideText);
    this.renderer.appendChild(shieldDiv, textNode);
    this.renderer.appendChild(this.el.nativeElement, shieldDiv);
    this.applyStyle(this.viewStyle);
  }

  private hide() {
    this.applyStyle({
      visibility: 'hidden',
      ...this.viewStyle
    });
  }

  private none() {
    this.applyStyle({
      display: 'none',
      ...this.viewStyle
    });
  }
}
